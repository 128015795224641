.content__grid-events {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    .content__grid-events-item {
        flex: 1 1 calc((100% / var(--quantity-items-grid, 3)) - (2 * 1rem / var(--quantity-items-grid, 3))); /* Ajusta el ancho de los elementos */
        min-width: calc((100% / var(--quantity-items-grid, 3)) - (2 * 1rem / var(--quantity-items-grid, 3)));
        max-width: calc((100% / var(--quantity-items-grid, 3)) - (2 * 1rem / var(--quantity-items-grid, 3)));
        .card {
            // width: 450px;
            max-width: 100%;
            .image_container {
                aspect-ratio: 1 / 1;
                height: auto;
            }
        }
    }
}