@import "vars";
// Font Family
// @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@font-face { font-family: 'perfect-condensed-font'; src: url('../public/assets/fonts/perfect-condensed-font/tgsperfectcondensed.otf');}
@font-face { font-family: 'noir-pro'; src: url('../public/assets/fonts/noir-pro/NoirPro-Regular.otf'); font-weight: normal;}
@font-face { font-family: 'noir-pro'; src: url('../public/assets/fonts/noir-pro/NoirPro-SemiBold.otf'); font-weight: bold;}

// Global Styles
@import "../../../src/styles/vars";
@import "../../../src/styles/styles";

@import url("./tailwind.css");

body.webapp-main {
  @import "styles/main";
  @import "styles/responsive";
}
