// RGB Colors: Example #000000 => 0, 0, 0
$rgb-primary-color: (
  red($primary-color),
  green($primary-color),
  blue($primary-color)
);
$rgb-secondary-color: (
  red($secondary-color),
  green($secondary-color),
  blue($secondary-color)
);
$rgb-tertiary-color: (
  red($tertiary-color),
  green($tertiary-color),
  blue($tertiary-color)
);

$rgb-success-color: (
  red($success-color),
  green($success-color),
  blue($success-color)
);
$rgb-danger-color: (
  red($danger-color),
  green($danger-color),
  blue($danger-color)
);
$rgb-warning-color: (
  red($warning-color),
  green($warning-color),
  blue($warning-color)
);
$rgb-info-color: (red($info-color), green($info-color), blue($info-color));

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --tertiary-color: #{$tertiary-color};
  // * for RGBA
  --rgb-primary-color: #{$rgb-primary-color};
  --rgb-secondary-color: #{$rgb-secondary-color};
  --rgb-tertiary-color: #{$rgb-tertiary-color};

  --danger-color: #{$danger-color};
  --warning-color: #{$warning-color};
  --info-color: #{$info-color};
  --success-color: #{$success-color};
  // * for RGBA
  --rgb-danger-color: #{$rgb-danger-color};
  --rgb-warning-color: #{$rgb-warning-color};
  --rgb-info-color: #{$rgb-info-color};
  --rgb-success-color: #{$rgb-success-color};

  // * Font
  --font-title: #{$font-title};
  --font-subtext: #{$font-subtext};
  --font-text: #{$font-text};
}
