body {
  --swiper-pagination-bottom: 30px;
  // --swiper-pagination-top: 4%;
  --swiper-pagination-color: $primary-color;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-inactive-color: #2A2A2A;
  --swiper-pagination-color: #e7e7e7;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 8px;
}
.bg_light {
  background-color: $bg-color-light;
}
.bg_dark {
  background: $bg-color-dark !important;
}
.bg_custom {
  background: radial-gradient( 170.84% 28.38% at 91.25% 7.87%, #201b39 0%, #080812 100% ) !important;
}

h1 {
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  color: $primary-color;
  font-family: perfect-condensed-font;
}
h2 {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.3;
  p {
    display: inline-block;
  }
}
h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  padding: 0 .3rem;
}
h4 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3;
}
p {
  font-size: 16px;
  font-weight: 400;
  &.p_title {
    font-size: 16px;
    font-weight: 600;
  }
}
hr {
  border-color: #d9d9d9;
}

.container{ @apply md:px-2; }
.btn {
  border-style: solid;
  border-width: 1px;
  padding: 0.45rem 0.75rem;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  &.btn_big{
    padding: 0.8rem 2rem;
    font-size: 1.15rem;
    line-height: 1.3;
    width: 100%;
    max-width: 400px;
  }
  &.btn_sm {
    padding: .4rem .5rem;
    font-size: .5rem;
    font-size: .9rem;
    line-height: 1.3;
    width: fit-content;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    img{
      height: 1rem;
    }
  }
  &.btn_big_2 {
    font-size: 1rem;
    padding: 0.7rem 0.7rem;
    line-height: 1.3;
  }
  &.btn_big_3 {
    padding: 0.8rem 2rem;
    font-size: 1.15rem;
    line-height: 1.3;
  }
  &.btn_white {
    border-color: #fff;
    color: #000;
    background-color: #fff;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
    &.btn_outline {
      color: #fff;
      background-color: transparent;
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
  &.btn_black {
    border-color: #000;
    color: #fff;
    background-color: #000;
    &:hover {
      background-color: transparent;
      color: #000;
    }
    &.btn_outline {
      color: #000;
      background-color: transparent;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
  &.btn_primary {
    border-color: var(--primary-color);
    color: #fff;
    background-color: var(--primary-color);
    &:disabled {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
      color: #9f9e9e;
      cursor: not-allowed;
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:hover {
      background-color: darken($primary_color, 10%);
    }
    &.btn_outline {
      color: var(--primary-color);
      background-color: transparent;
      &:hover {
        background-color: var(--primary-color);
        color: #fff;
      }
    }
  }
  &.btn_secondary {
    border-color: var(--secondary-color);
    color: #fff;
    background-color: var(--secondary-color);
    &:disabled {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
      color: #9f9e9e;
      cursor: not-allowed;
      &:hover {
        background-color: #e5e5e5;
      }
    }
    &:hover {
      background-color: darken($primary_color, 10%);
    }
    &.btn_outline {
      color: var(--secondary-color);
      background-color: transparent;
      &:hover {
        background-color: var(--secondary-color);
        color: var(--secondary-color);
      }
    }
  }
}
.relative {
  position: relative;
}
.mobile {
  display: none !important;
}
.desktop {
  display: block;
}

.banner_blur_container {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  background: var(--tertiary-color);
  transition: height 0.3s ease-in-out;
  align-items: center;
  padding-top: 72px;
}
.banner_blur {
  top: -10%;
  left: -10%;
  right: -10%;
  width: 120%;
  filter: blur(3rem);
  height: 100%;
  margin: 0 auto;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  -webkit-filter: blur(3rem);
  background-size: 1240px;
  background-repeat: no-repeat;
  background-position: center center;
}
.banner_blur_container .content_container {
  position: relative;
  z-index: 1;
  justify-content: space-between;
  padding: 3.5rem 0rem 8rem;
  gap: 1rem;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.banner_blur_container .content_container .content_text {
  width: 384px;
  color: #fff;
  font-size: 2.4375rem;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner_blur_container .content_container .content_text h2 {
  font-size: 1.3rem;
  opacity: 0.7;
}

.banner_blur_container .content_container .content_image .main_image {
  height: 400px;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.underline_custom {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 2px;
    background-color: #b0b0b0;
    left: 0;
    bottom: -15px;
    right: 0;
    margin: auto;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
  }
  &.under_active::after {
    width: 80%;
  }
}
.badge {
  @apply me-2 px-2.5 py-0.5 rounded-full w-fit flex items-center justify-center gap-1;
  padding: 0.2rem 1rem;
  margin-inline-end: 0px;
  cursor: pointer;
  img {
    width: 16px;
  }
  span{ font-size: .8rem }
  &.badge_square {
    @apply rounded-md;
    padding: 0.1rem 0.5rem;
  }
  &.badge_white {
    @apply bg-white text-[#344054];
  }
  &.badge_dark {
    @apply bg-[#2a2a2a] text-white;
    &.badge_active {
      background-color: #fff;
      color: #344054;
    }
  }
  &.badge_sky {
    @apply bg-[#CDE7FE] text-[#005099];
  }
  &.badge_primary {
    @apply bg-primary text-[#fff];
  }

  &.badge_type-pending-payment {
    @apply bg-red-300 text-red-800;
  }
  &.badge_type-paid {
    @apply bg-teal-300 text-teal-800;
  }
}
.grid_63_37 {
  display: grid;
  grid-template-columns: 63% 37%;
  gap: 0.5rem;
  width: calc(100% - 0.5rem);
}
.grid_90 {
  display: grid;
  grid-template-columns: 120px auto;
  gap: 1rem;
  width: calc(100% - 1rem);
}
.grid_3 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 1rem;
  &.grid_small{ gap: .75rem 1rem; }
  &.change_4 {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  }

  &.auto_fit {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  &.end_2 {
    gap: 0.5rem;
  }
}
.grid_credit_cards{ display: grid; grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); gap: .5rem; }
.grid_card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 1rem;
  input{ text-align: center; }
}
.grid_2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(465px, 1fr));
  gap: 1.25rem 1.5rem;
  width: 100%;
}
.grid_layout_ticket {
  display: grid;
  grid-template-columns: 65.5% 34.5%;
  gap: 2rem;
  width: calc(100% - 2rem);
}
.fadeInDown{ animation: fadeInDown .2s ease-in; -webkit-animation: fadeInDown .2s ease-in; }

/*==============  acordeon  ===============*/
.accordion img{ transition: all .2s ease-in; -webkit-transition: all .2s ease-in; -moz-transition: all .2s ease-in; -ms-transition: all .2s ease-in; -o-transition: all .2s ease-in;
  &.flip{ transform: rotateZ(180deg); -webkit-transform: rotateZ(180deg); -moz-transform: rotateZ(180deg); -ms-transform: rotateZ(180deg); -o-transform: rotateZ(180deg); }
}

/*==============  header  ===============*/

nav ul li a span {
  font-family: var(--font-subtext);
  font-size: .9rem;
  // font-weight: bold;
  text-transform: uppercase;
}

/*==============  custom  ===============*/

.loading_app {
  z-index: 2;
  display: flex;
  opacity: 1;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: radial-gradient(
    189.31% 48.41% at 48.47% 52.41%,
    #242424 0%,
    #000000 100%
  );
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  &.visible_hide {
    opacity: 0;
  }
  &.hide {
    display: none;
  }
  img {
    margin-bottom: 15%;
    max-width: 230px;
  }
  .legend {
    color: #fff;
    position: absolute;
    bottom: 17%;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    .version {
      opacity: 0.3;
      text-align: center;
      margin-top: 0.3rem;
    }
  }
}
.characteristic {
  text-align: center;
  padding: 1.5rem;
  color: #fff;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #1d1d1d;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center 250px;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &.characteristic_1::before {
    background-image: url(/assets/icons/layer1.svg);
  }
  &.characteristic_2::before {
    background-image: url(/assets/icons/layer2.svg);
  }
  img {
    width: 100%;
    max-width: 60%;
    max-height: 375px;
    object-fit: contain;
    z-index: 1;
  }
  .btn,
  h2,
  p {
    z-index: 1;
  }
}
.swiper_custom_width {
  max-width: 100%;
}
.dark_gradient{ background: linear-gradient(192.04deg, #222124 7.86%, #000000 92.14%); position: relative; }
.card {
  background-color: $card-color;
  color: $text-color;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  &.card_carousel {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    .image_container{
      height: 469px;
    }
    .description {
      width: 100%;
      padding: 3rem 0.7rem 1.5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      h4 {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
      p {
        min-height: 48px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }
  .image_container{
    background-color: #000;
    position: relative;
    width: 100%;
    height: 384px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.out_of_stock::after {
      content: "AGOTADO";
      color: #fff;
      height: fit-content;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      margin: auto;
      inset: 0 0 0 0;
      background-color: $info-color;
      transform: rotate(-9deg) scale(1.2);
      padding: 0.1rem;
      font-weight: 500;
    }
    &.soon::after {
      content: "PRÓXIMAMENTE";
      color: #fff;
      height: fit-content;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      margin: auto;
      inset: 0 0 0 0;
      background-color: $info-color;
      transform: rotate(-9deg) scale(1.2);
      padding: 0.1rem;
      font-weight: 500;
    }
  }
  .description {
    padding: 1rem 0.7rem;
    h4 {
      height: 44px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}
.save_ticket {
  z-index: 1;
  background-color: #f2f4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 10px;
  top: 10px;
  &.save_details {
    background-color: #fff;
    &.active {
      svg path {
        fill: #101828;
      }
    }
  }
  &.active {
    animation: bounceIn 0.7s ease-in;
    -webkit-animation: bounceIn 0.7s ease-in;
    svg path {
      fill: #667085;
    }
  }
}
.card_color {
  width: 100%;
  height: 80px;
  position: relative;
  color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  h4 {
    position: absolute;
    bottom: 1rem;
    left: 0.7rem;
    word-break: break-all;
  }
  &.card_orange {
    background: linear-gradient(255.95deg, #f8744e 0%, #c62825 97.12%);
  }
  &.card_light_orange {
    background: linear-gradient(255.95deg, #f8b74e 0%, #c65525 97.12%);
  }
  &.card_blue {
    background: linear-gradient(255.95deg, #003a7a 0%, #1986c9 97.12%);
  }
  &.card_light_blue {
    background: linear-gradient(255.95deg, #4e98f8 0%, #2a25c6 97.12%);
  }
  &.card_red {
    background: linear-gradient(255.95deg, #da1564 0%, #920941 97.12%);
  }
  &.card_purple {
    background: linear-gradient(255.95deg, #8636cd 0%, #431091 97.12%);
  }
  &.card_light_purple {
    background: linear-gradient(255.95deg, #874ef8 0%, #9e25c6 97.12%);
  }
}
.ticket_details {
  position: relative;
  .main_image_container{
    width: 100%;
    height: 288px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: var(--tertiary-color);
    transition: height 0.3s ease-in-out;
    padding-top: 72px;
  }
  .main_image {
    width: 400px;
    height: 400px;
    top: 0;
    left: 0;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 400px;
  }
  .details_card {
    padding: 26px;
    background-color: #fff;
    width: 100%;
    p {
      color: rgba(#000, 0.6);
      &.p_title {
        color: #000;
      }
    }
  }
  .main_card {
    height: fit-content;
    border: 0.063rem solid #d0d5dd;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .complement_card {
    background-color: #fff;
    width: 100%;
    height: fit-content;
    top: 20px;
    position: sticky;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .complement_card_inner{
      padding: 26px;
      border: 1px solid #d0d5dd;
      overflow: hidden;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }
  }
  .tab_container button {
    border-bottom-color: #d0d5dd;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    color: rgba(#000, 0.6);
    line-height: 1.3;
  }
  .border-b-patch {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #fff;
      z-index: 1;
    }
  }
}
.tab_container {
  display: flex;
  button {
    border-bottom-color: transparent;
  }
}
.card_info {
  &.bordered-botom{
    border-bottom: 1px solid #d0d5dd; padding-bottom: 1.25rem; margin-bottom: 1.25rem;
  }
  &.bordered{
    border-bottom: unset;
    border: 1px solid #d0d5dd;
    padding: 0.7rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  &.type_grid {
    display: grid;
    grid-template-columns: 24px auto;
    gap: 0.8rem;
  }
  &.type_grid_mob {
    display: none;
    grid-template-columns: 24px auto;
    gap: 0.8rem;
  }
}
.seats_badge{
  &.badge_active{
    color: #fff;
    background: var(--primary-color);
  }
  @apply line-clamp-1 text-sm flex items-center justify-center;
  cursor: pointer;
  text-align: center;
  line-height: 1.1;
  background: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: .25rem;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
  width: 88px;
  transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -ms-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
}

.general_card {
  h3{ padding: 0; }
  &.bordered{ border: 1px solid #d0d5dd; }
  padding: 26px;
  background-color: #fff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  .item__list-lines {
    padding-top: 4px;
    padding-bottom: 4px;
    &:first-child {
      padding-top: 4px !important;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
      padding-bottom: .7rem;
      padding-top: .7rem;
    }
    &:last-child {
      padding-top: .7rem;
    }
  }
}
.iframe_video {
  width: 100%;
  max-width: 700px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.map-container {
  position: relative;
  width: 100%;
  height: 427px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  .absolute-map {
    position: absolute;
    z-index: 1;
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: calc(100% - 20px);
    max-width: 400px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    top: 0px;
    left: 0px;
    padding: 10px;
  }
}
.menu_mob {
  z-index: 2;
  background-color: #000;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #fff;
  .menu_mob_inner {
    padding: 0.7rem 0;
    max-width: 313px;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    &.type_3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.type_4 {
      grid-template-columns: repeat(4, 1fr);
    }
    .menu_mob_item {
      display: grid;
      justify-content: center;
      gap: 0.2rem;
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
      img {
        margin: auto;
      }
      span {
        font-size: 11px;
        text-align: center;
      }
    }
  }
}
.toast-container .ngx-toastr {
  box-shadow: unset;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  .toast-title {
    font-size: 18px;
  }
  .toast-message {
    font-size: 14px;
  }
  &:hover {
    box-shadow: 0 0 12px #999999;
    box-shadow: unset;
  }
}
.toast-error {
  background-color: var(--danger-color) !important;
}
.toast-success {
  background-color: #0cce6b !important;
}
/*==============  modal  ===============*/
.mat-mdc-dialog-content {
  max-height: 80vh !important;
}

/*==============  forms  ===============*/
.swal2-confirm {
  background-color: var(--primary-color);
}
.swal2-cancel {
  background-color: #000;
}
.custom_search_wrapper {
  align-items: center;
  padding: 0.5rem 1rem;
  border: 2px solid #ffffff80;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 0.5rem;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  &::before {
    content: "";
    background-image: url("/assets/icons/search_gray.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom_search {
    color: #fff;
    width: 100%;
    background-color: transparent;
    &::placeholder {
      color: #667085;
    }
  }
}
.custom_select {
  position: relative;
  width: 100%;
  &::before {
    z-index: 0;
    content: "";
    background-image: url(/assets/icons/chevron-down.svg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.7rem;
    margin: auto;
  }
  select {
    width: 100%;
    z-index: 1;
    background-color: transparent;
    position: relative;
    appearance: none;
    border: 1px solid #475467;
    padding: 0.5rem 2rem 0.5rem 1rem;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    &:focus-visible {
      border: 1px solid #475467;
    }
  }
}
.custom_radio {
  height: 100%;
  position: relative;
  width: fit-content;
  padding: 0.29rem 0;
  &:has(:disabled), &:has(.disabled) {
    label {
      background: #ddd !important;
      border-color: #ddd !important;
      color: #8d8d8d !important;
    }
  }
  [type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
  /* Base for label styling */
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  [type="radio"]:not(:checked) + label {
    border: 1px solid #d0d5dd;
    position: relative;
    padding: 0.4rem 1rem;
    font-size: 15px;
    line-height: 1.3;
    cursor: pointer;
    word-break: break-word;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  [type="radio"]:checked + label {
    border: 1px solid var(--primary-color);
    z-index: 0;
    color: #fff;
    position: relative;
    padding: 0.4rem 1rem;
    font-size: 15px;
    line-height: 1.3;
    cursor: pointer;
    word-break: break-word;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  /* checked mark aspect */
  [type="radio"]:not(:checked) + label:after,
  [type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    background-color: var(--primary-color);
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    overflow: hidden;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  /* checked mark aspect changes */
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
  }

  [type="radio"]:disabled:checked + label:after {
    color: #777;
  }
  [type="radio"]:disabled + label {
    color: #aaa;
  }
}
.custom_radio_2 {
  height: 100%;
  position: relative;
  width: fit-content;
  padding: 0.29rem 0;
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  [type="radio"]:not(:checked) + label,
  [type="radio"]:checked + label {
    position: relative;
    display: grid;
    grid-template-columns: 24px auto;
    gap: 0.5rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    cursor: pointer;
    word-break: break-word;
  }

  [type="radio"]:not(:checked) + label:before,
  [type="radio"]:checked + label:before {
    content: "";
    position: relative;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #fff;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:checked + label:before {
    content: "";
    position: relative;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 6px solid var(--primary-color);
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }

  [type="radio"]:disabled:not(:checked) + label:before,
  [type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="radio"]:disabled:checked + label:after {
    color: #777;
  }

  [type="radio"]:disabled + label {
    color: #aaa;
  }
}
.custom_radio_3{
  &.no_top span{
    &:before{ margin-top: 0; }
  }
  .title{ font-size: 1rem ; color: #000; text-transform: capitalize;}
  P{ font-size: .9rem; margin-bottom: .1rem; }
  label { display: flex; cursor: pointer; font-weight: 500; position: relative; overflow: hidden;
    input { position: absolute; left: -9999px;
        &:checked + span { background-color: rgba(var(--rgb-primary-color), 0.05); border-color: var(--primary-color);
            &:before { box-shadow: inset 0 0 0 0.3375em var(--primary-color); }
        }
        & + span { border-style: solid; border-width: 1px; border-color: transparent; }
    }
    span { background-color: #d0d5dd34; width: 100vw; max-width: 100%; display: grid; grid-template-columns: 35px auto; padding: .7rem; border-radius: 3px; transition: 0.25s ease; -webkit-border-radius: 3px; -moz-border-radius: 3px; -ms-border-radius: 3px; -o-border-radius: 3px;
      &.no_circle{ grid-template-columns: 100%; padding: .7rem 1rem;
        &::before{ content: unset; }
      }
      &:hover{ background-color: rgba(var(--rgb-primary-color), 0.05); }
      &:before { margin-top: .5rem; display: flex; flex-shrink: 0; content: ""; background-color: #fff; width: 1.3em; height: 1.3em; border-radius: 50%; transition: 0.25s ease; box-shadow: inset 0 0 0 0.125em #a2a2a2; -webkit-border-radius: 50%; -moz-border-radius: 50%; -ms-border-radius: 50%; -o-border-radius: 50%; }
    }
  }
}
.custom_checkbox {
  height: 100%;
  position: relative;
  width: fit-content;
  padding: 0.29rem 0;
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.7rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
    cursor: pointer;
    word-break: break-word;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + label:before,
  [type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 0.2em;
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + label:after,
  [type="checkbox"]:checked + label:after {
    content: "";
    background: url(/assets/icons/check_red.svg) center;
    background-size: 95%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1.4em;
    height: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: var(--primary-color);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }

  /* Disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + label:before,
  [type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  [type="checkbox"]:disabled:checked + label:after {
    color: #777;
  }

  [type="checkbox"]:disabled + label {
    color: #aaa;
  }
}
.form_wrapper {
  display: block;
  label {
    display: block;
    font-size: 16px;
    font-weight: 600;
  }
  input,
  select {
    display: block;
    background-color: transparent;
    font-size: 16px;
    padding: 0.2rem 0;
    color: #475467;
    width: 100%;
    &:focus,
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #475467a5;
    }
  }
}
.form_wrapper_2 {
  display: block;
  position: relative;
  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 0.2rem;
  }
  .pass_wrapper {
    padding-top: 1px;
    position: relative;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &.control_invalid {
      border: 1px solid var(--danger-color);
    }
    input {
      border: none;
      padding-right: 40px;
    }
    .hide_wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto;
      width: 20px;
      cursor: pointer;
      height: fit-content;
      &.hide::after {
        content: "";
        margin: auto;
        background-color: #fff;
        border-top: 2px solid #777777;
        width: 120%;
        height: 3px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        position: absolute;
        top: 10%;
        bottom: 0;
        left: -8%;
        transform: rotateZ(-45deg);
        -webkit-transform: rotateZ(-45deg);
        -moz-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        -o-transform: rotateZ(-45deg);
        animation: fadeIn 0.2s ease-in;
        -webkit-animation: fadeIn 0.2s ease-in;
      }
      img {
        max-width: 100%;
      }
    }
  }
  input,
  select {
    border: 1px solid #d0d5dd;
    font-weight: 400;
    width: 100%;
    padding: 0.6rem 0.7rem;
    font-size: 1rem;
    color: #000;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    &:focus,
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: #667085;
    }
    &.control_invalid {
      border: 1px solid var(--danger-color);
    }
  }
  p {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    margin-top: 0.1rem;
    img {
      max-width: 15px;
      width: 100%;
    }
    label {
      margin-bottom: 0;
      color: var(--danger-color);
    }
  }
}

/*==============  credit card  ===============*/
.card_wrapper{ min-height: 100%;
  .checkout, .checkout2 {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .credit-card-box {
    perspective: 1000;
    width: 400px;
    height: 250px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &.hover .flip {
      transform: rotateY(180deg);
    }

    .front,
    .back {
      width: 400px;
      height: 250px;
      border-radius: 15px;
      backface-visibility: hidden;
      background: linear-gradient(135deg, #bd6772, #53223f);
      position: absolute;
      color: #fff;
      top: 0;
      left: 0;
      text-shadow: 0 1px 1px hsla(0, 0, 0, 0.3);
      box-shadow: 0 1px 6px hsla(0, 0, 0, 0.3);

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: .05;
      }
    }

    .flip {
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;
    }

    .logo {
      position: absolute;
      top: 9px;
      right: 20px;
      width: 60px;

      svg {
        width: 100%;
        height: auto;
        fill: #fff;
      }
    }

    .front {
      z-index: 2;
      transform: rotateY(0deg);
    }

    .back {
      transform: rotateY(180deg);

      .logo {
        top: 185px;
      }

    }

    .chip {
      position: absolute;
      width: 60px;
      height: 45px;
      top: 20px;
      left: 20px;
      background: linear-gradient(135deg, hsl(269,54%,87%) 0%,hsl(200,64%,89%) 44%,hsl(18,55%,94%) 100%);;
      border-radius: 8px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border: 4px solid hsla(0, 0, 50, .1);
        width: 80%;
        height: 70%;
        border-radius: 5px;
      }
    }

    .strip {
      background: linear-gradient(135deg, hsl(0, 0, 25%), hsl(0, 0, 10%));
      position: absolute;
      width: 100%;
      height: 50px;
      top: 30px;
      left: 0;
    }

    .number {
      position: absolute;
      margin: 0 auto;
      top: 103px;
      left: 19px;
      font-size: 36px;
    }

    label {
      font-size: 10px;
      letter-spacing: 1px;
      text-shadow: none;
      text-transform: uppercase;
      font-weight: normal;
      opacity: 0.5;
      display: block;
      margin-bottom: 3px;
    }

    .card-holder,
    .card-expiration-date {
      position: absolute;
      margin: 0 auto;
      top: 180px;
      left: 19px;
      font-size: 22px;
      text-transform: capitalize;
    }

    .card-expiration-date {
      text-align: right;
      left: auto;
      right: 20px;
    }

    .ccv {
      height: 36px;
      background: #fff;
      width: 91%;
      border-radius: 5px;
      top: 110px;
      left: 0;
      right: 0;
      position: absolute;
      margin: 0 auto;
      color: #000;
      text-align: right;
      padding: 10px;

      label {
        margin: -35px 0 8px;
        color: #fff;
      }
    }


  }

  .the-most {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 50vw;
    max-width: 200px;
    padding: 10px;

    img {
      max-width: 100%;
    }
  }
}

/*==============  loader  ===============*/
.loads-ellipsis {
  &.min-loader{ width: 40px; height: 22px; transform: scale(.7); -webkit-transform: scale(.7); -moz-transform: scale(.7); -ms-transform: scale(.7); -o-transform: scale(.7);
    div{ top:0; bottom: 0; margin: auto;}
  }
  margin: 0 auto;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}
.loading_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  .backdrop_loading {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .loading__content {
    display: block;
    width: auto;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.5rem 2rem;
    border-radius: 15px;
    p {
      display: block;
      margin: 0;
      text-align: center;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

/*==============  animation  ===============*/
@keyframes fadeIn {
  0% {opacity: 0}
  100% { opacity: 1; }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.content_modal {
  &.type__qr {
    div[mat-dialog-content].mat-mdc-dialog-content {
      max-height: 100%;
      aspect-ratio: 1 / 1;
      --mat-dialog-with-actions-content-padding: 5px;
    }
  }
}

si-seatsio-seating-chart {
  .seatsio-loading-screen {
    max-width: 100%;
  }
}

.text_three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

span.maskImage {
  width: 1em;
  height: 1em;
  display: block;
  background: currentColor;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.text__error {
  color: var(--danger-color);
  font-size: 14px;
}

mat-stepper {
  &.stepper_not-step-header .mat-horizontal-stepper-header-container {
    display: none;
    opacity: 0;
    height: 0; 
    width: 0; 
    pointer-events: none; 
    position: absolute; 
  }
  &.stepper_not-padding {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    .mat-horizontal-content-container {
      padding: 1rem 0 0;
      text-align: left;
    }
  }
}

.content__forgot-password {
  mat-stepper {
    background: transparent;
    padding: 0;
    .mat-horizontal-content-container {
      padding: 0;
    }
  }
}

.swal2-title {
  font-size: 22px;
}