// Material Angular
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "ngx-toastr/toastr";

.font__title {
  font-family: $font-title;
}
.font__text {
  font-family: $font-text;
}

html {
  @extend .font__text;
  height: 100%;
}
html,
body {
  height: 100%;
}
// Custom Styles
@import "master/main";
@import "master/responsive";
