$primary-color: #020041;
$secondary-color: #F26513;
$tertiary-color: #00153A;

$success-color: #40d565;
$danger-color: #e5092a;
$warning-color: #ffd227;
$info-color: #1AC0F4;

$font-title: "perfect-condensed-font", sans-serif;
$font-subtext: "noir-pro", sans-serif;
$font-text: "Inter", sans-serif;

// Other customizations
$bg-color-light: #f5f5f5;
$bg-color-dark: #020536;
$card-color: #DDD1BB;
$text-color: #000;
