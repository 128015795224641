@media (min-width: 1536px) {
  .container {
    // max-width: 1436px;Yerson Armando
    max-width: 1200px;
  }
}
@media (min-width: 1280px) {
  .container {
    // max-width: 1436px;Yerson Armando
    max-width: 1200px;
  }
}
// md tailwind
@media screen and (min-width: 768px) {
}

// sm Tailwind
@media screen and (min-width: 640px) {
}

@media screen and (min-width: 361px) and (max-width: 767px) {
}

@media screen and (min-width: 1024px) {
}

@media screen and (max-width: 992px) {
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
    &.p_title {
      font-size: 16px;
    }
  }
  .content_modal {
    &.type__qr {
      .mat-mdc-dialog-content{
        height: 1000px;
      }
      .modal_body{
        height: 10000px;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .card_color h4{ max-width: 86%; }
  .bg_light {
    background-color: #F5F5F5;
  }
  .card_wrapper{
    .checkout2{ display: none; }
    .checkout{ padding: 0; }
    .grid_3{ grid-template-columns: repeat(auto-fill, minmax(125px, 1fr)); }
    .form_wrapper_2 input{ padding-left: 7px; padding-right: 7px;}
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none;
  }
  .grid_layout_ticket {
    grid-template-columns: 100%;
    width: 100%;
  }
  .btn_big {
    width: 100%;
  }
  .iframe_video {
    height: 220px;
  }
  .grid_2 {
    grid-template-columns: 100%;
  }
  .grid_3 { grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    &.end_2 {
      grid-template-columns: repeat(2, 1fr);
      .last_item {
        grid-column: 1 / 3;
      }
    }
    &.grid_small{ grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));}
  }
  .swiper_custom_width {
    max-width: 86%;
  }
  .badge span{ font-size: .9rem }
  .dark_gradient{ background: transparent; }
  .ticket_details {
    padding-top: 340px;
    .main_image_container {
      position: fixed;
      height: 360px;
      top: 0;
      left: 0;
      padding-top: 0;
    }
    .main_card {
      overflow: visible;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
    }
    .details_card {
      padding: 16px 8px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      position: sticky;
      bottom: 0px;
      min-height: calc(100vh - 310px);
      position: relative;
      z-index: 1;
    }
    .card_info.type_grid_mob {
      display: grid;
    }
  }
  .general_card {
    padding: 16px 8px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .ticket_details {
    .main_image {
      background-size: 360px;
    }
  }
  .card {
    &.card_carousel {
      .image_container {
        height: 345px;
      }
    }
    .image_container {
      height: 134px;
    }
  }
}

@media screen and (max-width: 767px) {
  .title_ticket {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    width: 100%;
    h4 {
      &.name {
        font-size: 24px;
        color: var(--primary-color);
        font-weight: 800;
        margin-bottom: 0.5rem;
      }
      &.price {
        font-size: 20px;
      }
    }
  }

  .to_price-float {
    margin-top: -2.5rem;
  }
}

@media screen and (max-width: 639px) {
  .grid_3.grid_small.grid__form {
    grid-template-columns: minmax(0, 1fr);
  }
}
