@media screen and (max-width: 1024px) {
    .container__home-events {
        width: 100% !important;
        max-width: 100% !important;
    }
}

@media screen and (max-width: 991px) {
    .dark_gradient {
        background: linear-gradient(192.04deg, #222124 7.86%, #000000 92.14%);
    }
    .card.card_carousel {
        color: white;
    }
    .content__grid-events {
        .content__grid-events-item {
            --quantity-items-grid: 2;
        }
    }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 545px) {
    .content__grid-events {
        .content__grid-events-item {
            --quantity-items-grid: 1;
        }
    }
}